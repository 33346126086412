import React from 'react';
import { Container, Row, Column } from 'good-css-react';
import Image1_640x640 from 'images/content/gallery-1-640x640.jpg';
import Image1_320x320 from 'images/content/gallery-1-320x320.jpg';
import Image2_640x640 from 'images/content/gallery-2-640x640.jpg';
import Image2_320x320 from 'images/content/gallery-2-320x320.jpg';
import Image3_640x640 from 'images/content/gallery-3-640x640.jpg';
import Image3_320x320 from 'images/content/gallery-3-320x320.jpg';
import Image4_640x640 from 'images/content/gallery-4-640x640.jpg';
import Image4_320x320 from 'images/content/gallery-4-320x320.jpg';
import Image5_640x640 from 'images/content/gallery-5-640x640.jpg';
import Image5_320x320 from 'images/content/gallery-5-320x320.jpg';

const Gallery = () => {
  return (
    <div className="c-gallery">
      <Container>
        <Row>
          <Column size={{ sm: '10', md: '6', xl: '5' }}>
            <div className="c-gallery__content">
              <h2 className="c-gallery__title">
                Pratite našu avanturu na{' '}
                <a
                  href="https://www.instagram.com/agilo_sports/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Instagramu
                </a>
                !
              </h2>
            </div>
          </Column>

          <Column size={{ md: '6', xl: '7' }}>
            <div className="c-gallery-grid">
              <div className="c-gallery-grid__item">
                <img
                  loading="lazy"
                  src={Image1_640x640}
                  srcSet={`${Image1_320x320} 320w, ${Image1_640x640} 640w`}
                  sizes="(max-width: 640px) 100vw, 640px"
                  width="320"
                  height="320"
                  alt="Agilo igrači na terenu tokom nogometne utakmice."
                  className="c-gallery-grid__image"
                />
              </div>

              <div className="c-gallery-grid__item">
                <img
                  loading="lazy"
                  src={Image2_640x640}
                  srcSet={`${Image2_320x320} 320w, ${Image2_640x640} 640w`}
                  sizes="(max-width: 640px) 100vw, 640px"
                  width="320"
                  height="320"
                  alt="Agilo ekipa okupljena na nogometnom terenu."
                  className="c-gallery-grid__image"
                />
              </div>

              <div className="c-gallery-grid__item">
                <img
                  loading="lazy"
                  src={Image3_640x640}
                  srcSet={`${Image3_320x320} 320w, ${Image3_640x640} 640w`}
                  sizes="(max-width: 640px) 100vw, 640px"
                  width="320"
                  height="320"
                  alt="Agilo igrači na terenu tokom nogometne utakmice."
                  className="c-gallery-grid__image"
                />
              </div>

              <div className="c-gallery-grid__item">
                <img
                  loading="lazy"
                  src={Image4_640x640}
                  srcSet={`${Image4_320x320} 320w, ${Image4_640x640} 640w`}
                  sizes="(max-width: 640px) 100vw, 640px"
                  width="320"
                  height="320"
                  alt="Agilo igrači na terenu tokom nogometne utakmice."
                  className="c-gallery-grid__image"
                />
              </div>

              <div className="c-gallery-grid__item">
                <img
                  loading="lazy"
                  src={Image5_640x640}
                  srcSet={`${Image5_320x320} 320w, ${Image5_640x640} 640w`}
                  sizes="(max-width: 640px) 100vw, 640px"
                  width="320"
                  height="320"
                  alt="Agilo igrači na terenu tokom nogometne utakmice."
                  className="c-gallery-grid__image"
                />
              </div>
            </div>
          </Column>
        </Row>
      </Container>
    </div>
  );
};

export default Gallery;
