import React from 'react';
import { Container, Row, Column, Button } from 'good-css-react';

const Cta = () => {
  return (
    <div className="c-cta">
      <Container>
        <Row>
          <Column>
            <h2 className="c-cta__title">
              Želite li se priključiti našoj inicijativi i <br />
              podignuti vrijednost gola?
            </h2>

            <Button as="a" href="mailto:foundation@agilo.co">
              Kontaktirajte nas
            </Button>
          </Column>
        </Row>
      </Container>
    </div>
  );
};

export default Cta;
