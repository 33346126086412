import React from 'react';
import { Container, Row, Column } from 'good-css-react';
import Statistics from 'components/Statistics';
import Image1_678x452 from 'images/content/image-1-678x452.jpg';
import Image1_750x500 from 'images/content/image-1-750x500.jpg';
import Image1_1356x904 from 'images/content/image-1-1356x904.jpg';
import Image1_1500x1000 from 'images/content/image-1-1500x1000.jpg';

const Hero = () => {
  return (
    <div className="c-hero">
      <Container>
        <Row>
          <Column size={{ lg: '8' }}>
            <Row>
              <Column size={{ xl: '9' }} mx={{ xl: 'auto' }}>
                <div className="c-hero__body">
                  <h1 className="c-hero__title">Svaki gol za više dobro!</h1>

                  <p>
                    <a
                      href="https://agilo.co/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Agilo
                    </a>{' '}
                    je digitalna agencija sportskog duha, trenutno u pohodu na
                    vrh malonogometne lige firmi{' '}
                    <a
                      href="http://www.suncitysport.com/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Sun City Sport
                    </a>{' '}
                    u Splitu.
                  </p>

                  <p>
                    Za svaki gol koji zabijemo za vrijeme lige odlučili smo
                    donirati 100 kn u humanitarne svrhe. Skupljeni iznos donirat
                    ćemo na kraju sezone.
                  </p>

                  <p>
                    U ovoj su nam se akciji pridružili i partneri koji su nas
                    odlučili podržati te podignuti vrijednost gola.
                  </p>
                </div>
              </Column>
            </Row>

            <Row>
              <Column size="12">
                <Statistics />
              </Column>
            </Row>
          </Column>

          <Column size="3" ml="auto" display={{ xs: 'hidden', lg: 'block' }}>
            <img
              src={Image1_1500x1000}
              srcSet={`${Image1_678x452} 678w, ${Image1_750x500} 750w, ${Image1_1356x904} 1356w, ${Image1_1500x1000} 1500w`}
              sizes="(max-width: 1500px) 100vw, 1500px"
              alt="Agilo ekipa okupljena na nogometnom terenu."
              className="c-hero__image"
            />
          </Column>
        </Row>
      </Container>
    </div>
  );
};

export default Hero;
