import React from 'react';
import CountUp from 'react-countup';

const COUNT_UP_DURATION = 2;
const VRIJEDNOST_PO_GOLU = 800;
const BROJ_GOLOVA = 74;

const Statistics = () => {
  return (
    <div className="c-statistics">
      <div className="c-statistics__item">
        <p className="c-statistics__number">
          <CountUp end={BROJ_GOLOVA} duration={COUNT_UP_DURATION} />
        </p>

        <p className="c-statistics__label">
          <em>Broj golova</em> ⚽
        </p>
      </div>

      <div className="c-statistics__item">
        <p className="c-statistics__number">
          <CountUp
            end={VRIJEDNOST_PO_GOLU}
            duration={COUNT_UP_DURATION}
            suffix=" kn"
          />
        </p>

        <p className="c-statistics__label">
          <em>Vrijednost po golu</em> 💰
        </p>
      </div>

      <div className="c-statistics__item">
        <p className="c-statistics__number">
          <CountUp
            end={BROJ_GOLOVA * VRIJEDNOST_PO_GOLU}
            duration={COUNT_UP_DURATION}
            suffix=" kn"
            separator="."
          />
        </p>

        <p className="c-statistics__label">
          <em>Skupljeni iznos</em> ❤️
        </p>
      </div>
    </div>
  );
};

export default Statistics;
