import React from 'react';
import { Container, Row, Column, Button } from 'good-css-react';

const Header = () => {
  return (
    <div className="c-header">
      <Container>
        <Row>
          <Column size="12">
            <div className="c-header__inner">
              <div className="c-header-logo">
                <a
                  href="https://agilo.co/"
                  className="c-header-logo__link"
                  aria-label="Agilo = Design + Technology"
                >
                  <span className="u-hidden">Agilo = Design + Technology</span>
                  <svg
                    width="120"
                    height="24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    xmlnsXlink="http://www.w3.org/1999/xlink"
                    viewBox="0 0 120 24"
                  >
                    <rect
                      x="0"
                      y="0"
                      width="120"
                      height="24"
                      fill="#fff"
                      fillOpacity="0"
                    />
                    <path
                      d="M46.79 17.672c-.58-7.222-1.012-11.866-1.302-13.934h-.145l-6.876 13.64-2.75 4.934c-.434.763-.831 1.125-1.447 1.125-.29 0-.795-.218-1.194-.362-1.084-.4-1.084-1.053-.685-1.778l10.853-19.99C43.75.362 44.437 0 45.413 0h1.558c.432 0 1.338.219 1.48 1.344l2.498 19.953c.035.255.035.472.035.687 0 1.053-.436 1.453-2.172 1.453-.759 0-1.158-.362-1.303-1.234l-.721-4.531h.003zm37.71 5.403c-1.231 0-1.484-.797-1.267-1.778l4.16-19.919C87.574.472 87.864 0 88.478 0h1.412c1.338 0 1.338.653 1.193 1.378L87.21 19.772h9.917c.832 0 1.013.547 1.013 1.416 0 1.343-.725 1.885-1.702 1.885H84.5l-.001.002zm18.02-16.508C103.496 1.742 105.304 0 109.683 0h4.199c5.933 0 5.607 4.969 5.138 7.292l-1.808 8.817c-.976 4.828-2.677 6.966-7.057 6.966h-4.488c-5.933 0-5.536-5.006-5.065-7.328l1.917-9.18zm12.81 1.125l.072-.255c.541-2.937.034-4.135-2.208-4.135h-3.476c-2.571 0-3.112 1.198-3.727 4.209l-1.699 8.052c-.652 3.159-.253 4.209 2.21 4.209h3.327c2.894 0 3.22-1.197 3.803-4.063l1.698-8.015v-.002zM78.706 1.38C78.89.473 79.18 0 79.794 0h1.412c1.34 0 1.34.653 1.195 1.378l-4.234 20.5c-.181.906-.47 1.378-1.087 1.378h-1.41c-1.338 0-1.338-.653-1.196-1.378l4.234-20.5-.001.002zM39.447 14.366h7.527l.256 3.301h-9.375l1.59-3.301h.002zm28.55-2.503c.185-.907.51-1.379 1.126-1.379h1.231c1.337 0 1.337.653 1.19 1.38l-1.882 9.105c-.291 1.489-1.195 2.106-2.571 2.106h-7.668c-3.943 0-5.972-2.177-5.211-5.95L56.347 6.75C57.502 1.05 59.963 0 63.871 0h7.273c.834 0 1.013.544 1.013 1.416 0 1.343-.721 1.886-1.698 1.886h-6.55c-2.606 0-3.44 1.415-3.909 3.843l-1.956 8.82c-.651 2.865.18 3.808 2.787 3.808h5.61l1.555-7.909.002-.002z"
                      fill="#333"
                    />
                    <path
                      d="M23.504 22.313c-.436.762-.794 1.125-1.412 1.125-.29 0-1.935-.22-2.334-.363-1.086-.4-1.049-1.053-.686-1.778l8.972-16.763c.436-.76 1.013-1.125 1.629-1.125.29 0 1.683.22 2.08.363 1.087.398 1.087 1.051.686 1.778l-8.933 16.762h-.002z"
                      fill="#DF4718"
                    />
                    <path
                      d="M14.423 22.297c-.433.762-.794 1.125-1.41 1.125-.29 0-1.936-.219-2.335-.363-1.084-.4-1.048-1.053-.685-1.778l7.271-13.57c.437-.761.76-1.125 1.375-1.125.29 0 1.936.219 2.335.362 1.086.399 1.086 1.052.685 1.779l-7.234 13.57h-.002z"
                      fill="#EAC513"
                    />
                    <path
                      d="M5.41 22.313c-.435.762-.794 1.125-1.411 1.125-.29 0-1.936-.22-2.335-.363-1.084-.4-1.048-1.053-.685-1.778L6.368 11.03c.436-.762.76-1.125 1.376-1.125.288 0 1.932.219 2.331.36 1.087.4 1.087 1.053.686 1.78L5.409 22.314h.002z"
                      fill="#09C5B3"
                    />
                  </svg>
                </a>

                <p className="c-header-logo__tag">designxculturextechnology</p>
              </div>

              <div className="u-hidden u-inline-block@sm">
                <Button as="a" href="mailto:foundation@agilo.co">
                  Kontaktirajte nas
                </Button>
              </div>
            </div>
          </Column>
        </Row>
      </Container>
    </div>
  );
};

export default Header;
