import React from 'react';
import Header from 'components/Header';
import Hero from 'components/Hero';
import Partners from 'components/Partners';
import Cta from 'components/Cta';
import Gallery from 'components/Gallery';
import { Fade } from 'react-awesome-reveal';

const App = () => {
  return (
    <>
      <Fade triggerOnce>
        <Header />
      </Fade>

      <Fade triggerOnce>
        <Hero />
      </Fade>

      <Fade triggerOnce>
        <Partners />
      </Fade>

      <Fade triggerOnce>
        <Gallery />
      </Fade>

      <Fade triggerOnce>
        <Cta />
      </Fade>
    </>
  );
};

export default App;
