import React from 'react';
import { Container, Row, Column } from 'good-css-react';
import Logo1 from '../images/content/logo-bitcoin-store.svg';
import Logo2 from '../images/content/logo-hotel-krilo.png';
import Logo3 from '../images/content/logo-boban-i-partneri.svg';
import Logo4 from '../images/content/logo-abis-consulting.svg';
import Logo5 from '../images/content/logo-dentelli.svg';
import Logo6 from '../images/content/logo-sun-city-sport.svg';
import Logo7 from '../images/content/logo-raic-gradnja.svg';

const Partners = () => {
  return (
    <div className="c-partners">
      <Container>
        <Row>
          <Column>
            <h2 className="c-partners__title">Partneri</h2>
          </Column>
        </Row>

        <Row>
          <Column>
            <div className="c-partners-grid">
              <div className="c-partners-grid__item">
                <a
                  href="https://abis-croatia.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="c-partners-grid__link"
                >
                  <img
                    src={Logo4}
                    width="144"
                    height="26"
                    loading="lazy"
                    alt="Abis Consulting"
                    className="c-partners-grid__image"
                  />
                </a>
              </div>

              <div className="c-partners-grid__item">
                <a
                  href="https://www.bitcoin-store.hr/"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="c-partners-grid__link"
                >
                  <img
                    src={Logo1}
                    width="131"
                    height="50"
                    loading="lazy"
                    alt="Bitcoin Store"
                    className="c-partners-grid__image"
                  />
                </a>
              </div>

              <div className="c-partners-grid__item">
                <a
                  href="https://hotelkrilo.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="c-partners-grid__link"
                >
                  <img
                    src={Logo2}
                    width="218"
                    height="100"
                    loading="lazy"
                    alt="Hotel Krilo"
                    className="c-partners-grid__image"
                  />
                </a>
              </div>

              <div className="c-partners-grid__item">
                <a
                  href="https://bobanipartneri.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="c-partners-grid__link"
                >
                  <img
                    src={Logo3}
                    width="124"
                    height="50"
                    loading="lazy"
                    alt="Boban i Partneri"
                    className="c-partners-grid__image"
                  />
                </a>
              </div>

              <div className="c-partners-grid__item">
                <a
                  href="http://dentelli.hr/"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="c-partners-grid__link"
                >
                  <img
                    src={Logo5}
                    width="213"
                    height="68"
                    loading="lazy"
                    alt="Dentelli"
                    className="c-partners-grid__image"
                  />
                </a>
              </div>

              <div className="c-partners-grid__item">
                <a
                  href="http://www.suncitysport.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="c-partners-grid__link"
                >
                  <img
                    src={Logo6}
                    width="213"
                    height="68"
                    loading="lazy"
                    alt="Sun City Sport"
                    className="c-partners-grid__image"
                  />
                </a>
              </div>

              <div className="c-partners-grid__item">
                <a
                  href="http://www.raicgradnja.hr/hr/"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="c-partners-grid__link"
                >
                  <img
                    src={Logo7}
                    width="164"
                    height="62"
                    loading="lazy"
                    alt="Raić Gradnja"
                    className="c-partners-grid__image"
                  />
                </a>
              </div>
            </div>
          </Column>
        </Row>
      </Container>
    </div>
  );
};

export default Partners;
